export const CLEAR_GUEST_WISHLIST = 'CLEAR_GUEST_WISHLIST';
export const UPDATE_ITEM_OPTIONS_IN_GUEST_WISHLIST = 'UPDATE_ITEM_OPTIONS_IN_GUEST_WISHLIST';
export const REMOVE_ITEM_FROM_GUEST_WISHLIST = 'REMOVE_ITEM_FROM_GUEST_WISHLIST';
export const UPDATE_ALL_PRODUCTS_IN_GUEST_WISHLIST = 'UPDATE_ALL_PRODUCTS_IN_GUEST_WISHLIST';
export const UPDATE_IS_LOADING_IN_GUEST_WISHLIST = 'UPDATE_IS_LOADING_IN_GUEST_WISHLIST';

/**
 * Remove specified product from wishlist.
 * @param  {Object} product Product which should be removed.
 * @return {void}
 */
export const removeItemFromWishlist = (item_id) => ({
    type: REMOVE_ITEM_FROM_GUEST_WISHLIST,
    item_id
});

/**
 * Update all products in wishlist.
 * @param  {Array} products Products that should be updated in wishlist.
 * @return {void}
 */
export const updateAllProductsInWishlist = (products) => ({
    type: UPDATE_ALL_PRODUCTS_IN_GUEST_WISHLIST,
    products
});

export const updateIsLoading = (isLoading) => ({
    type: UPDATE_IS_LOADING_IN_GUEST_WISHLIST,
    isLoading
});

export const updateItemOptions = (options) => ({
    type: UPDATE_ITEM_OPTIONS_IN_GUEST_WISHLIST,
    options
});

export const clearWishlist = () => ({
    type: CLEAR_GUEST_WISHLIST
});
