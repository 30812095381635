import { MyAccountQuery as SourceMyAccountQuery } from 'SourceQuery/MyAccount.query';
import { Field } from 'Util/Query';
import BrowserDatabase from 'Util/BrowserDatabase';
import { GUEST_QUOTE_ID } from 'Store/Cart/Cart.dispatcher';

export class MyAccountQuery extends SourceMyAccountQuery {
    getSignInMutation(options) { // send empty string if there are no guest quote id
        const { email, password } = options;
        const guestQuoteId = BrowserDatabase.getItem(GUEST_QUOTE_ID);

        return new Field('generateCustomerToken')
            .addArgument('email', 'String!', email)
            .addArgument('password', 'String!', password)
            .addArgument('guest_quote_id', 'String!', guestQuoteId || '')
            .addField('token');
    }

    getLogOutMutation(options) {
        return new Field('logoutCustomer')
            .addArgument('input', 'LogoutInput!', options)
            .addField('status');
    }

    getCreateAccountMutation(options) {
        const { customer, password, card_type, card_number } = options;

        return new Field('createCustomer')
            .addArgument('input', 'CustomerInput!', { ...customer, password, card_type, card_number })
            .addField(this._getCustomerField());
    }

    _getCustomerFields() { // added 'gender, 'tip_ten', 'tip_par', 'this._getLoyaltyField
        return [
            'created_at',
            'confirmation_required',
            'group_id',
            'prefix',
            'firstname',
            'middlename',
            'lastname',
            'suffix',
            'email',
            'default_billing',
            'default_shipping',
            'dob',
            'taxvat',
            'id',
            'is_subscribed',
            'abandoned_cart_subscribed',
            'pdf_url',
            'gender',
            'tip_ten',
            'tip_par',
            this._getSamedayLocker(),
            this._getAddressesField(),
            this._getLoyaltyField(),
            this._getBFMessages(),
            this._getLoyaltyInfoField(),
            this._getMyAccountPromoBlockId(),
            this._getRewardVouchersField(),
            this._getBSSRewardPoints()
        ];
    }

    _getBSSRewardPoints() {
        return new Field('reward_point')
            .addFieldList([
                'point',
                'point_used',
                'point_expired',
                'point_earned',
                'amount',
                'notify_balance',
                'notify_expiration',
                'rate_point',
                new Field('subscribe_point').addFieldList(['point', 'message']),
                'first_review_point',
                'birth_day_point',
                'points_inactive'
            ]);
    }

    _getLoyaltyInfoField() {
        return new Field('loyalty_info')
            .addFieldList(['card_status', 'card_number', 'customer_level', 'customer_total_sales', 'until_next_level', 'no_default_address_warning', 'seen_enrolled']);
    }

    _getMyAccountPromoBlockId() {
        return new Field('promoBlocks')
            .addFieldList(this._getMyAccountPromoBlockFields());
    }

    _getMyAccountPromoBlockFields() {
        return [
            'my_account_promo_block_id',
            'my_account_promo_bottom_block_id',
            'my_account_bronze_card_block_id',
            'my_account_silver_card_block_id',
            'my_account_gold_card_block_id'
        ];
    }

    _getRewardVouchersField() {
        return new Field('reward_vouchers')
            .addFieldList(['voucher_code', 'voucher_value', 'voucher_type', 'expires_at']);
    }

    _getAddressFields() { // added 'company', 'cui', 'nr_registrul_comertului', 'persoana_fizica_juridica'
        return [
            'id',
            'customer_id',
            'country_id',
            'street',
            'telephone',
            'postcode',
            'city',
            'firstname',
            'lastname',
            'middlename',
            'prefix',
            'suffix',
            'default_shipping',
            'default_billing',
            'company',
            'cui',
            'nr_registrul_comertului',
            'persoana_fizica_juridica',
            this._getRegionField()
        ];
    }

    _getLoyaltyField() {
        return new Field('loyalty')
            .addFieldList(this._getLoyaltyFields());
    }

    _getLoyaltyFields() {
        return [
            'cart_title',
            'discount',
            'total_orders',
            'highest_discount',
            'cart_color'
        ];
    }

    _getBFMessages() {
        return new Field('bf_message')
            .addFieldList(['show']);
    }

    getLogoutMutation() { // social login
        return new Field('logout')
            .addField('status');
    }

    // Frequency discount
    getFrequencyDiscount() {
        return new Field('getFrequencyDiscountDetails')
            .addFieldList(this._getFrequencyDiscountFields());
    }

    _getFrequencyDiscountFields() {
        return [
            'discount_percentage_amount',
            'min_order_amount',
            'frequency_balance_amount',
            'is_active',
            'expire_day'
        ]
    }

    _getSamedayLocker() {
        return new Field('sameday_lockers')
            .addFieldList([
                'sameday_locker_id',
                'sameday_locker_name'
            ]
            );
    }

}

export default new MyAccountQuery();
