import React from 'react';
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

export const mapStateToProps = (state) => ({
    default_description: state.MetaReducer.default_description,
    default_keywords: state.MetaReducer.default_keywords,
    default_title: state.MetaReducer.default_title,
    canonical_url: state.MetaReducer.canonical_url,
    title_prefix: state.MetaReducer.title_prefix,
    title_suffix: state.MetaReducer.title_suffix,
    description: state.MetaReducer.description,
    keywords: state.MetaReducer.keywords,
    title: state.MetaReducer.title,
    robots: state.MetaReducer.robots,
    og_image: state.MetaReducer.og_image,
    og_type: state.MetaReducer.og_type,
    fallback_og_image: state.ConfigReducer.getOgImageUrl
});

class MetaContainer extends React.Component {
    static propTypes = {
        default_description: PropTypes.string,
        default_keywords: PropTypes.string,
        default_title: PropTypes.string,
        canonical_url: PropTypes.string,
        title_prefix: PropTypes.string,
        title_suffix: PropTypes.string,
        description: PropTypes.string,
        keywords: PropTypes.string,
        title: PropTypes.string,
        robots: PropTypes.string,
        og_image: PropTypes.string,
        og_type: PropTypes.string,
        fallback_og_image: PropTypes.string,
    };

    static defaultProps = {
        default_description: '',
        default_keywords: '',
        default_title: '',
        canonical_url: '',
        title_prefix: '',
        title_suffix: '',
        description: '',
        keywords: '',
        title: '',
        robots: 'index, follow',
        og_image: '',
        og_type: '',
    };

    render() {
        const {
            title,
            description,
            keywords,
            robots,
            canonical_url,
            og_image,
            og_type,
            title_prefix,
            title_suffix,
            default_title,
            default_description,
            default_keywords,
            fallback_og_image,
        } = this.props;

        const metaTitle = title || default_title;
        const metaDescription = description || default_description;
        const metaKeywords = keywords || default_keywords;
        const finalOgImage = og_image || fallback_og_image || '';

        return (
            <Helmet>
                <title>{title_prefix}{metaTitle}{title_suffix}</title>
                {canonical_url && <link rel="canonical" href={canonical_url} />}
                {/* Description */}
                <meta name="description" content={metaDescription} />
                <meta property="description" content={metaDescription} />
                {/* Keywords */}
                <meta name="keywords" content={metaKeywords} />
                <meta property="keywords" content={metaKeywords} />
                {/* Robots */}
                <meta name="robots" content={robots} />
                <meta property="robots" content={robots} />
                {/* Open Graph */}
                {finalOgImage && <meta property="og:image" content={finalOgImage} />}
                {og_type && <meta property="og:type" content={og_type} />}
                <meta property="og:title" content={metaTitle} />
                <meta property="og:description" content={metaDescription} />
            </Helmet>
        );
    }
}

export default connect(mapStateToProps)(MetaContainer);
