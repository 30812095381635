import BrowserDatabase from 'Util/BrowserDatabase';
import { getIndexedParameteredProducts, transformProductAttributesForGuestWishlist } from 'Util/Product';

import {
    CLEAR_GUEST_WISHLIST,
    REMOVE_ITEM_FROM_GUEST_WISHLIST,
    UPDATE_ALL_PRODUCTS_IN_GUEST_WISHLIST,
    UPDATE_IS_LOADING_IN_GUEST_WISHLIST,
    UPDATE_ITEM_OPTIONS_IN_GUEST_WISHLIST
} from './GuestWishlist.action';

export const GUEST_PRODUCTS_IN_WISHLIST = 'guest_wishlist_products';

export const initialState = {
    guestProductsInWishlist: BrowserDatabase.getItem(GUEST_PRODUCTS_IN_WISHLIST) || {},
    isLoading: true
};

const deleteProperty = (key, { [key]: _, ...newObj }) => newObj;

const removeItemFromWishlist = ({ item_id }, { guestProductsInWishlist: initialProducts }) => {
    const guestProductsInWishlist = deleteProperty(item_id, initialProducts) || {};

    BrowserDatabase.setItem(
        guestProductsInWishlist,
        GUEST_PRODUCTS_IN_WISHLIST
    );

    return { guestProductsInWishlist };
};

const clearWishlist = () => {
    const guestProductsInWishlist = {};

    BrowserDatabase.setItem(guestProductsInWishlist, GUEST_PRODUCTS_IN_WISHLIST);
    return { guestProductsInWishlist };
};

const updateAllProductsInWishlist = (action) => {
    const { products: initialProducts } = action;

    const products = getIndexedParameteredProducts(initialProducts);

    const mappedProducts = Object.fromEntries(Object.entries(products).map(([k,v]) => [k, transformProductAttributesForGuestWishlist(v)]));

    BrowserDatabase.setItem(
        mappedProducts,
        GUEST_PRODUCTS_IN_WISHLIST
    );

    return { guestProductsInWishlist: mappedProducts, isLoading: false };
};

const updateItemOptions = (options, { guestProductsInWishlist }) => {
    const { item_id } = options;
    const cleanedOptions = deleteProperty('item_id', options) || {};

    const products = {
        ...guestProductsInWishlist,
        [item_id]: {
            ...guestProductsInWishlist[item_id],
            wishlist: {
                ...guestProductsInWishlist[item_id].wishlist,
                ...cleanedOptions
            }
        }
    };

    BrowserDatabase.setItem(
        products,
        GUEST_PRODUCTS_IN_WISHLIST
    );

    return { guestProductsInWishlist: products };
};

const GuestWishlistReducer = (state = initialState, action) => {
    const { type, options } = action;

    switch (type) {
        case REMOVE_ITEM_FROM_GUEST_WISHLIST:
            return {
                ...state,
                isLoading: false,
                ...removeItemFromWishlist(action, state)
            };

        case CLEAR_GUEST_WISHLIST:
            return {
                ...state,
                ...clearWishlist()
            };

        case UPDATE_ALL_PRODUCTS_IN_GUEST_WISHLIST:
            return {
                ...state,
                isLoading: false,
                ...updateAllProductsInWishlist(action)
            };

        case UPDATE_ITEM_OPTIONS_IN_GUEST_WISHLIST:
            return {
                ...state,
                ...updateItemOptions(options, state)
            };

        case UPDATE_IS_LOADING_IN_GUEST_WISHLIST:
            const { isLoading } = action;

            return {
                ...state,
                isLoading
            };

        default:
            return state;
    }
};

export default GuestWishlistReducer;
